<template>
  <div>
    <PageHeader main-header="nav-bar.orders" />
    <v-tabs show-arrows class="sticky-el--menu">
      <v-tab :to="{ name: 'orders-serving' }" @click="$helper.scrollTop">{{
        $t('order.serving')
      }}</v-tab>
      <v-tab
        v-if="cashier"
        :to="{ name: 'orders-invoices' }"
        @click="$helper.scrollTop"
        >{{ $t('order.closed') }}</v-tab
      >
      <v-tab :to="{ name: 'orders-cancelled' }" @click="$helper.scrollTop">{{
        $t('order.cancelled')
      }}</v-tab>
    </v-tabs>
    <router-view></router-view>
    <ViewOrder
      v-if="orderDialog"
      :dialog.sync="orderDialog"
      :order="selectedOrder"
      :type="objectType"
      :toUpdate.sync="updateOrder"
      @openAddRequest="openAddRequest"
    />
    <OrderAddRequest
      v-if="addRequestDialog"
      :dialog.sync="addRequestDialog"
      :order="selectedOrder"
      :type="objectType"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import { hasRole } from '@/utils'
const ViewOrder = () => import('@/components/orders/ViewOrder')
const OrderAddRequest = () => import('@/components/orders/OrderAddRequest')

export default {
  name: 'Orders',

  components: {
    PageHeader,
    ViewOrder,
    OrderAddRequest
  },

  data: () => ({
    orderDialog: false,
    addRequestDialog: false,
    selectedOrder: null,
    viewOrderType: null,
    updateOrder: false
  }),

  computed: {
    cashier() {
      return hasRole('Cashier')
    }
  },

  mounted() {
    this.$mitt.on('openViewOrder', this.openViewOrder)
    this.$mitt.on('openAddRequest', this.openAddRequest)
  },
  beforeDestroy() {
    this.$mitt.off('openViewOrder', this.openViewOrder)
    this.$mitt.off('openAddRequest', this.openAddRequest)
  },

  watch: {
    orderDialog(val) {
      if (!val) {
        this.$mitt.emit('closeOrderDialog')
      }
    }
  },

  methods: {
    openViewOrder(payload) {
      this.selectedOrder = payload.item
      this.objectType = payload.type
      this.orderDialog = true
      if (payload.update) {
        this.updateOrder = true
      } else {
        this.updateOrder = false
      }
    },

    openAddRequest(payload) {
      this.selectedOrder = payload.item
      this.objectType = payload.type
      this.addRequestDialog = true
    }
  }
}
</script>

<style scoped></style>
